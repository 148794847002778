import { changeUser, reauthenticate } from 'src/store/modules/auth/actions'
import { getToken, getUser, IUser, whoami } from 'src/services/Auth'
import { redirectTo } from 'src/utils/redirectTo'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'src/store/types'
import useFetchData from './useFetchData'
import Uris from '../services/Uris'
import { setPublicPrivate } from '../store/modules/general/actions'

enum Status {
  OK = 200,
  FORBIDDEN = 403,
  UNAUTHENTICATED = 401
}

type TRole = 'student' | 'admin' | 'no_login'

interface Options {
  isAuthRequired?: boolean
  role?: TRole[]
}

export const useUser = ({ isAuthRequired = false, role = null }: Options) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const [status, setStatus] = useState<Status>(null)
  const token = useSelector(({ auth }) => auth.token)
  const user = useSelector(({ auth }) => auth.user)
  const isLoading = useSelector(({ commons }) => commons.loader)
  const isVerifyUser = (user: any, token: string): Status => {
    if (isAuthRequired) {
      if (!token) return Status.UNAUTHENTICATED
      if ((!user || !user?.role || !role?.includes(user?.role as TRole)) && role?.length !== 0) return Status.FORBIDDEN
      return Status.OK
    }

    if (token && role?.includes('no_login')) return Status.FORBIDDEN
    return Status.OK
  }

  useEffect(() => {
    const cookieToken = getToken()
    const cookieUser = getUser()

    dispatch(reauthenticate(cookieToken))
    dispatch(changeUser(cookieUser))
  }, [])

  useEffect(() => {
    (async () => {
      const cookieToken = getToken()
      const cookieUser = getUser()
      const userStatus = isVerifyUser(cookieUser, cookieToken)
      if (userStatus === Status.FORBIDDEN && !!user && !!user?.role) {
        const route = redirectTo(user?.role)
        await router.replace(route)
      }
      setStatus(userStatus)
    })()
  }, [user, token])

  useEffect(() => {
    if (user && status === 200) dispatch(setPublicPrivate(false))
  }, [user, status])

  if (status === Status.UNAUTHENTICATED && isLoading) return {}

  return { user, status }
}

export const useProfile = () => {
  const dispatch = useDispatch()
  const { data: user } = useFetchData<IUser>({
    uri: Uris.user.whoami,
    fetcher: () => whoami(),
  })

  useEffect(() => {
    if (user) dispatch(changeUser(user))
  }, [user])

  return user
}
