export const redirectTo = (role) => {
  switch (role) {
    case 'student':
      return '/cursos'
    case 'admin':
      return '/admin/students'
    default:
      return '/'
  }
}
