import { QueryFunction, QueryKey, useInfiniteQuery, useQuery } from 'react-query'
import { ErrorHandler } from '../utils/errorHandler'
import { error as toastError } from '../utils/toast'

interface IUseRequest<T> {
  uri: string
  fetcher: QueryFunction<T, QueryKey>
  args?: any
  withToast?: boolean
  handleError?: (error) => void
  enabled?: boolean
  getNextPageParam?: (lastPage: T) => any
}

const handleErrors = (networkError: any, handleError?: (error) => void, withToast?: boolean) => {
  if (networkError) {
    if (!!handleError) handleError(networkError)
    if (withToast) {
      const errors = ErrorHandler(networkError)
      errors.forEach(({ error: { title, message, id } }) => {
        toastError(message, title, id)
      })
    }
  }
}

function useFetchData<T>({ uri, fetcher, args, withToast = true, enabled = true, handleError }: IUseRequest<T>) {
  const { data, error, isLoading } = useQuery<T>(!args ? uri : [uri, args], fetcher, {
    onError: (err) => handleErrors(err, handleError, withToast),
    refetchOnWindowFocus: false,
    enabled,
    retry: false,
  })
  return { data, error, isLoading }
}

export function useInfiniteFetchData<T>({ uri, fetcher, args, withToast = true, enabled = true, handleError, getNextPageParam }: IUseRequest<T>) {
  const { data, error, hasNextPage, fetchNextPage, isLoading } = useInfiniteQuery<T>(!args ? uri : [uri, args], fetcher, {
    enabled,
    getNextPageParam,
    onError: (err) => handleErrors(err, handleError, withToast),
  })
  return { data, error, hasNextPage, fetchNextPage, isLoading }
}

export default useFetchData
