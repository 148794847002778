import Error from 'src/pages/_error'
import { useUser } from 'src/hooks/useUser'
import dynamic from 'next/dynamic'
import { isServer } from 'src/utils/isServer'

const Layout = dynamic(() => import('src/components/templates/Platform'))

type TRole = 'student' | 'admin' | 'no_login'

interface IOptions {
  role: TRole[]
  isAuthRequired: boolean
  layout: boolean
}

const WithAuth = (Component, options?: IOptions) => {
  const WithAuthComponent = (props) => {
    if (!isServer) {
      const { layout, isAuthRequired, role } = options
      const { user, status } = useUser({ isAuthRequired, role })

      if (!status || status === 403) return null

      if (!user && status === 401) return <Error statusCode={status} user={null} />

      if (layout) {
        return (
          <Layout>
            <Component user={user} {...props} />
          </Layout>
        )
      }

      return <Component user={user} {...props} />
    }

    return null
  }

  if (!!Component.getInitialProps) {
    WithAuthComponent.getInitialProps = Component.getInitialProps
  }

  return WithAuthComponent
}

export default WithAuth
