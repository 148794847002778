import { Button as MButton } from '@mui/material'
import { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
  className?: string
  color?: 'primary' | 'secondary' | 'inherit'
  disabled?: boolean
  endIcon?: ReactNode
  fullWidth?: boolean
  onClick?: () => void
  size?: 'small' | 'large' | 'medium'
  startIcon?: ReactNode
  type?: 'submit'| 'reset' | 'button'
  variant?: 'contained' | 'text' | 'outlined'
  href?: string // Here
}

export const Button: FC<Props> = ({
  children,
  className,
  color,
  disabled = false,
  endIcon,
  fullWidth = false,
  onClick = () => null,
  size = 'medium',
  startIcon,
  type = 'button',
  variant = 'text',
  href,
}) => (
  <MButton
    disabled={disabled}
    size={size}
    className={className}
    color={color}
    fullWidth={fullWidth}
    onClick={onClick}
    startIcon={startIcon}
    endIcon={endIcon}
    type={type}
    variant={variant}
    href={href}
  >
    {children}
  </MButton>
)
