import { Action, AUTHENTICATE, CHANGE_USER } from 'src/store/modules/auth/types'
import { authenticate as authenticateService, socialLogin as socialLoginService, whoami, IUser } from 'src/services/Auth'
import { removeCookie, setCookie } from 'src/utils/cookie'

import * as StudentService from 'src/services/Student'
import { AuthActionTypes } from 'src/store/modules/auth/types/actions'
import { setPublicPrivate } from '../general/actions'

export const reauthenticate = (token?: string): AuthActionTypes => ({ type: AUTHENTICATE, payload: token })

export const changeUser = (user?: IUser): AuthActionTypes => ({ type: CHANGE_USER, payload: user })

export const authenticate = (email: string, password: string): Action => async (dispatch) => {
  const { access, user } = await authenticateService({ email, password })
  dispatch(changeUser(user))
  dispatch(reauthenticate(access))
}

export const signup = (signupObject): Action => async (dispatch) => {
  await StudentService.register({ ...signupObject, full_name: signupObject.fullName })
  await dispatch(authenticate(signupObject.email, signupObject.password))
}

export const socialLogin = (tokenId): Action => async (dispatch) => {
  const { access, user } = await socialLoginService(tokenId)
  setCookie('token', access)
  setCookie('user', JSON.stringify(user))
  dispatch(changeUser(user))
  dispatch(reauthenticate(access))
}

export const logout = (): Action => async (dispatch) => {
  removeCookie('user')
  removeCookie('token')
  dispatch(changeUser(null))
  dispatch(reauthenticate(''))
  dispatch(setPublicPrivate(true))
}

export const loadProfile = () => async (dispatch) => {
  const user = await whoami()
  dispatch(changeUser(user))
}

export const completeProfile = (profile) => async (dispatch) => {
  const data = await StudentService.complete(profile)
  dispatch(changeUser(data))
  setCookie('user', JSON.stringify(data))
}
