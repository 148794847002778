import Error from 'src/components/atoms/Error'
import { redirectTo } from 'src/utils/redirectTo'
import { IUser } from 'src/services/Auth'
import { useEffect, useState } from 'react'
import { removeCookie } from 'src/utils/cookie'
import { changeUser, reauthenticate } from 'src/store/modules/auth/actions'
import { useDispatch } from 'react-redux'
import { NextPage } from 'next'
import Link from 'next/link'
import { Button } from 'src/components/atoms/Button'

const getParams = (statusCode: number, role: string) => {
  const route = !!role ? role : ''
  switch (statusCode) {
    case 401:
      return {
        image: '/images/errors/401.png',
        buttonMessage: 'Ir a iniciar de sessión',
        route: '/login',
        title: 'Accesso no autorizado',
        message: 'Debe iniciar session para entrar a esta pagina.',
      }
    case 403:
      return {
        image: '/images/errors/403.png',
        buttonMessage: 'Volver al inicio',
        route: redirectTo(route),
        title: 'Accesso prohibido',
        message: 'No tiene permitido entrar a esta pagina.',
      }
    case 404:
      return {
        image: '/images/errors/404.png',
        buttonMessage: 'Volver al inicio',
        route: redirectTo(route),
        title: 'Pagina no encontrada',
        message: 'Lo sentimos esta pagina no ha sido encontrada.',
      }
    case 503:
      return {
        image: '/images/errors/503.png',
        buttonMessage: 'Volver al inicio',
        route: redirectTo(route),
        title: 'Servicio no disponible',
        message: 'Lo sentimos el servicio no esta disponible en estos momentos estamos tabajando para solucionarlo.',
      }
    default:
      return {
        image: '/images/errors/503.png',
        buttonMessage: 'Volver al inicio',
        route: redirectTo(route),
        title: 'Servicio no disponible',
        message: 'Lo sentimos el servicio no esta disponible en estos momentos estamos tabajando para solucionarlo.',
      }
  }
}

interface Props {
  statusCode: number
  user: IUser
}

const ErrorPage: NextPage<Props> = ({ statusCode, user }) => {
  let role = null
  const dispatch = useDispatch()

  if (!!user && !!user?.role) role = user?.role
  const { image, route, title, message, buttonMessage } = getParams(statusCode, role)
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    if (statusCode === 401) {
      removeCookie('token')
      removeCookie('user')
      dispatch(reauthenticate(''))
      dispatch(changeUser(null))
    }
    setDisabled(false)
  }, [])

  return (
    <Error title={title} message={message} image={image}>
      <Link href={route} passHref>
        <Button disabled={disabled} variant="contained" color="primary">
          {buttonMessage}
        </Button>
      </Link>
    </Error>
  )
}

export default ErrorPage
