import Axios from 'src/services/Axios'
import querystring from 'querystring'
import { IStudentWithPagination } from './interfaces'
import { IUser } from '../Auth'
import Uris from '../Uris'

export const getStudents = (params?: any): Promise<IStudentWithPagination> => {
  const query = querystring.stringify(params)
  const uri = Uris.admin.students.getAll
  return Axios.get(`${uri}?${query}`)
}

export const confirmationEmail = (email: string, code: string) => {
  const uri = '/api/students/'
  return Axios.post(`${uri}verify/`, { email, code })
}

export const resendConfirmationEmail = (email: string) => {
  const uri = '/api/students/'
  return Axios.post(`${uri}reconfirm/`, { email })
}

export const register = (student) => {
  const uri = '/api/students/'
  return Axios.post(`${uri}signup/`, student)
}

export const complete = (profile): Promise<IUser> => {
  const uri = '/api/students/'
  return Axios.put(`${uri}complete/`, profile)
}

export const removeAccount = () => {
  const uri = '/api/students/'
  return Axios.delete(`${uri}remove-account/`)
}
