import { RegisterModalOptions } from './types'
import { OPEN_REGISTER_MODAL, PUBLIC_PRIVATE } from './types/constants'

export const setPublicPrivate = (value: boolean) => (dispatch) => {
  dispatch({
    type: PUBLIC_PRIVATE,
    payload: value,
  })
}

export const openRegisterModal = (value?: RegisterModalOptions) => (dispatch) => {
  dispatch({
    type: OPEN_REGISTER_MODAL,
    payload: {
      ...value,
    },
  })
}
