import makeStyles from '@mui/styles/makeStyles'
import { FC, ReactNode } from 'react'
import { Typography } from 'src/components/atoms/Typography'
import { Image } from 'src/components/atoms/Image'

const useStyles = makeStyles(() => ({
  errorContent: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10vw',
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& span': {
      padding: '0 !important',
    },
    '& img': {
      position: 'relative !important',
      width: '100% !important',
      maxWidth: '800px !important',
      height: 'auto !important',
    },
  },

  message: {
    marginBottom: '20px',
    '& h2': {
      color: '#ffa304',
      fontWeight: '900',
    },
  },
}))

interface Props {
  image: string
  title?: string
  message?: string
  children?: ReactNode
}

const Error: FC<Props> = ({ image, title, message, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.errorContent}>
      <div className={classes.error}>
        <Image src={image} alt="" />
        <div className={classes.message}>
          <Typography variant="h2">Error</Typography>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle1">{message}</Typography>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Error
